<style>
/* .vue-treeselect__control {
	height: 31px !important;
} */
/* .input-group {
	height: 32px !important;
	overflow: hidden !important;
} */
.idSpan {
	background: #cad0d74a;
	padding: 10px;
	border-radius: 8px;
}
.radioStyleCheckBox label {
	padding-top: 2px !important;
}
/* .fz-12 {
	font-size: 12px !important;
} */
.scheduler-border {
	color: rgba(9, 54, 90, 0.74) !important;
	margin: 3px -8px !important;
	padding: 0 8px;
}
.littleBorderRad {
	border-top-right-radius: 10px !important;
	border-bottom-right-radius: 10px !important;
}
.modal-header .close {
	font-size: 38px;
}
label {
	margin-bottom: 0 !important;
}
</style>
<template>
	<b-modal
		id="premiseModal"
		header-class="header-class-modal-doc-package"
		:title="rendTitle"
		no-close-on-esc
		no-close-on-backdrop
		v-model="isOpen"
		size="xl"
		cancel-variant="custom-outline-gray"
		@hidden="hideModal"
		@close="cancelPremise"
		@ok="handleOk"
		@cancel="cancelPremise"
		:ok-title="FormMSG(300, 'Save')"
		ref="premiseModal"
	>
		<div>
			<div>
				<div>
					<div class="modal-body">
						<!-- <pre>{{ premise }}</pre> -->
						<div class="text-right">
							<label
								>{{ FormMSG(51, 'Premise no.') }} <span class="idSpan">P{{ premise.id }}</span>
							</label>
						</div>
						<fieldset class="scheduler-border">
							<legend class="scheduler-border">
								{{ FormMSG(200, 'General') }}
							</legend>

							<b-row class="pt-3">
								<b-col :cols="6">
									<b-form-group :label="FormMSG(3, 'Name')" label-for="name">
										<b-form-input class="form-control" v-model="premise.setName" id="name" type="text"></b-form-input>
									</b-form-group>
									<div v-if="validateSetName" class="invalid-feedback">
										{{ FormMSG(4, 'Please, enter a valid name') }}
									</div>
								</b-col>
								<b-col>
									<b-form-group :label="FormMSG(6, 'Address')" label-for="address">
										<v-address
											:params="{ title: FormMSG(547, 'Add address') }"
											:address-id="premise.addressId5"
											@change="handleDynamicAddressChange"
											@delete="handleDynamicAddressDelete"
											@address-maplink-change="handleAddressMapLinkChange"
										/>
										<!-- <b-form-group class="mb-0">
										<v-address
											:params="{ title: FormMSG(547, 'Add address') }"
											:address-id="premise.addressId5"
											@change="handleDynamicAddressChange"
											@delete="handleDynamicAddressDelete"
											@address-maplink-change="handleAddressMapLinkChange"
										/>
										<b-form-group class="mb-0">
											<b-input-group>
												<b-form-input
													readonly
													class="br-none"
													:value="formatAddress(premise.address5)"
													:placeholder="FormMSG(459, 'No selected address...')"
												/>
												<b-input-group-text class="bg-color-white input-group-text-bg-white">
													<button class="btn-transparent text-color-aqua-velvet" @click="handleClickAddAddress">
														<component v-if="premise.addressId5 == 0" :is="getLucideIcon('PlusCircle')" :size="20" />
														<component v-else :is="getLucideIcon('Edit')" :size="20" />
													</button>
												</b-input-group-text>
											</b-input-group>
										</b-form-group> -->
									</b-form-group>
									<div v-if="validateAddress" class="invalid-feedback">
										{{ FormMSG(61, 'Please, enter an address valid') }}
									</div>
								</b-col>
							</b-row>
							<b-row class="pb-3">
								<b-col sm="12" md="6" lg="6" xl="6">
									<b-form-group :label="FormMSG(10, 'Type of premises')" label-for="type">
										<v-select
											v-model="premise.type"
											:options="premiseOptions"
											label="text"
											:reduce="(option) => option.value"
											:placeholder="FormMSG(700, 'Select a type')"
											:clearable="false"
											:selectable="selectableTypeItem"
											:class="{ 'is-invalid': $v.premise.type.$error }"
											@option:selected="handleOptionSelected"
										>
											<template #option="option">
												<div
													:class="`${
														(option.disabled && option.disabled === true) || (option.selectable && option.selectable === true)
															? 'text-bold'
															: 'text-color ml-3'
													}`"
												>
													{{ option.text }}
												</div>
											</template>
										</v-select>
										<div v-if="$v.premise.type.$error" class="invalid-feedback">
											{{ FormMSG(701, 'Please, select a type') }}
										</div>
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-group :label="this.FormMSG(220, 'Production phase')" label-for="checkbox-group-1">
										<b-row class="lh-20">
											<b-col>
												<b-form-checkbox class="pj-cb pb-1" id="preproduction" v-model="premise.preproduction" size="lg">
													{{ FormMSG(217, 'Preproduction') }}
												</b-form-checkbox>
											</b-col>
											<b-col>
												<b-form-checkbox class="pj-cb pb-1" id="shooting" v-model="premise.shooting" size="lg">
													{{ FormMSG(218, 'Shooting') }}
												</b-form-checkbox>
											</b-col>
											<b-col>
												<b-form-checkbox
													v-if="premise.type === 1"
													class="pj-cb pb-1"
													id="postproduction"
													v-model="premise.postProduction"
													size="lg"
												>
													{{ FormMSG(219, 'Postproduction') }}
												</b-form-checkbox>
											</b-col>
										</b-row>
										<div v-if="!validateAtLeastOneProductionPhaseChecked" class="invalid-feedback">
											{{ FormMSG(702, 'Please, check at least one production phase') }}
										</div>
									</b-form-group>
								</b-col>
							</b-row>
						</fieldset>
						<fieldset class="scheduler-border">
							<legend class="scheduler-border">
								{{ FormMSG(204, 'Activities and occupation') }}
							</legend>
							<b-row class="pt-3 pb-4">
								<b-col sm="12" md="4" lg="4" xl="4">
									<b-form-group>
										<label for="occupation">
											<div style="display: flex; align-items: center">
												{{ FormMSG(11, 'Total no. of days occupation') }} &nbsp;
												<info-card>
													{{
														FormMSG(
															40,
															'Total number of days that the property will be occupied. Make sure to not include holidays or weekends (if applicable).'
														)
													}}
												</info-card>
											</div>
										</label>
										<b-input-group>
											<b-form-input id="occupation" type="number" v-model="premise.totNbOfDaysOccupation"></b-form-input>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													<span class="font-weight-bold"> {{ FormMSG(49, 'Days') }}</span>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col sm="12" md="4" lg="4" xl="4">
									<b-form-group>
										<label for="occupation">
											<div style="display: flex; align-items: center">{{ FormMSG(205, 'Office activity') }} &nbsp;</div>
										</label>
										<b-input-group>
											<b-form-input id="occupation" type="number" v-model="premise.totNbOfManDays"></b-form-input>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													<span class="font-weight-bold"> {{ FormMSG(207, 'man-days') }}</span>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col sm="12" md="4" lg="4" xl="4">
									<b-form-group>
										<label for="occupation">
											<div style="display: flex; align-items: center">{{ FormMSG(208, 'Working from home activity') }} &nbsp;</div>
										</label>
										<b-input-group>
											<b-form-input id="occupation" type="number" v-model="premise.workingFromHomeActivity"></b-form-input>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													<span class="font-weight-bold"> {{ FormMSG(207, 'man-days') }}</span>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
						</fieldset>
						<fieldset class="scheduler-border">
							<legend class="scheduler-border">
								{{ FormMSG(209, 'Additional info') }}
							</legend>

							<b-row class="pt-3">
								<b-col sm="12" md="4" lg="4" xl="4">
									<b-form-group :label="FormMSG(30, 'Area premises')" label-for="area">
										<b-input-group>
											<b-form-input type="number" id="area" v-model="premise.filmingArea"></b-form-input>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													<span class="font-weight-bold">m<sup>2</sup></span>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
										<div v-if="!validateAreaOfPremiseIfEstimationTrue" class="invalid-feedback">
											{{ FormMSG(704, 'Area of premise cannot be 0') }}
										</div>
									</b-form-group>
								</b-col>
								<b-col sm="12" md="4" lg="4" xl="4">
									<label>
										<div style="display: flex; align-items: center">
											{{ FormMSG(210, 'Is the building less than 30 years old ?') }} &nbsp;
										</div>
									</label>
									<b-form-radio-group
										v-model="premise.buildingLess30Yo"
										:options="yesNoIdontKnowOptions"
										class="radioStyleCheckBox pl-2 pj-cb pt-1"
										name="radio-inliness"
										size="lg"
									/>
								</b-col>
							</b-row>
							<b-row>
								<b-col sm="12" md="4" lg="4" xl="4">
									<b-form-checkbox class="pj-cb pb-1" id="placeheated" v-model="premise.placeHeated">
										{{ FormMSG(215, 'Place heated') }}
									</b-form-checkbox>
								</b-col>
								<b-col sm="12" md="4" lg="4" xl="4">
									<b-form-checkbox
										class="pj-cb pb-1"
										id="placeAirConditioned"
										@change="handleChangePlaceAirConditionned"
										v-model="premise.placeAirConditionned"
									>
										{{ FormMSG(216, 'Place air-conditionned') }}
									</b-form-checkbox>
								</b-col>
							</b-row>
							<b-row class="pt-3">
								<b-col sm="12" md="4" lg="4" xl="4">
									<b-form-group v-if="premise.placeHeated" :label="FormMSG(212, 'Heating type')" label-for="type">
										<v-select
											v-model="premise.heatingType"
											:options="heatingType"
											label="text"
											:reduce="(option) => option.value"
											:placeholder="FormMSG(700, 'Select a type')"
											:clearable="false"
											:selectable="selectableTypeItem"
											:class="{ 'is-invalid': !validateHeatingType }"
											@option:selected="handleOptionSelected"
										>
											<template #option="option">
												<div
													:class="`${
														(option.disabled && option.disabled === true) || (option.selectable && option.selectable === true)
															? 'text-bold'
															: 'text-color ml-3'
													}`"
												>
													{{ option.text }}
												</div>
											</template>
										</v-select>
										<div v-if="!validateHeatingType" class="invalid-feedback">
											{{ FormMSG(701, 'Please, select a type') }}
										</div>
									</b-form-group>
								</b-col>
								<b-col sm="12" md="4" lg="4" xl="4">
									<b-form-group v-if="premise.placeAirConditionned" :label="FormMSG(17, 'No. of air conditionners')" label-for="airCond">
										<b-input-group>
											<b-form-input
												:class="premise.estimation ? 'bg-color-grams-hair' : ''"
												:disabled="premise.estimation"
												v-model="premise.nbOfAirConditioners"
												id="airCond"
												type="text"
											></b-form-input>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													<span class="font-weight-bold"> {{ FormMSG(221, 'Units') }}</span>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
										<div v-if="!validateNbOfAirConditioners" class="invalid-feedback">
											{{ FormMSG(703, "The number can't be 0") }}
										</div>
									</b-form-group>
								</b-col>
								<b-col v-if="premise.placeAirConditionned" sm="12" md="4" lg="4" xl="4">
									<b-form-group class="pt-4">
										<b-form-checkbox size="lg" @change="handleChangeEstimation" v-model="premise.estimation" switch>
											<p>{{ FormMSG(213, 'Estimation') }}</p>
										</b-form-checkbox>
									</b-form-group>
								</b-col>
							</b-row>
						</fieldset>
						<fieldset class="my-0 py-0 pb-0 mt-4" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
							<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2">
								{{ FormMSG(698, 'Consumption') }}
							</legend>
							<div class="pb-4">
								<b-row>
									<b-col>
										<b-form-group class="pt-4">
											<b-form-checkbox v-model="premise.electricityFromPhotovoltaicSource">
												<label class="label-mobile">{{ FormMSG(214, 'Electricity from photovoltaic source') }}</label>
											</b-form-checkbox>
										</b-form-group>
									</b-col>
								</b-row>

								<view-consumption-energy
									:location="premise"
									:is-location="false"
									@view-consumption-energy:add-edit="handleClickeAddEditConsumption"
								/>
							</div>
						</fieldset>
						<fieldset class="scheduler-border">
							<legend class="scheduler-border">
								{{ FormMSG(20, 'Computer Equipment') }}
							</legend>
							<b-row class="pl-3" v-if="premise.computerEquipments.length > 0">
								<b-col :cols="3"
									><b>{{ FormMSG(21, 'Type') }}</b></b-col
								>
								<b-col :cols="3"
									><b>{{ FormMSG(22, 'Description') }}</b></b-col
								>
								<b-col :cols="2"
									><b>{{ FormMSG(23, 'Quantity') }}</b></b-col
								>
								<b-col :cols="3"
									><b>{{ FormMSG(25, 'Depreciation') }}</b></b-col
								>
							</b-row>
							<div v-if="premise.computerEquipments.length > 0" style="margin-top: -6px" class="pl-3">
								<div v-for="(equipment, index) in premise.computerEquipments" :key="index">
									<premises-equipment @delete="handleDelete" @change="handleChangeForEqp" :equipment="equipment" />
								</div>
							</div>
							<div v-else class="mt-2 w-100">
								{{ FormMSG(404, 'No computer equipment found') }}
							</div>
							<!-- <div v-else>No equipment yet</div> -->
							<div class="mt-3">
								<div class="mb-3" :class="premise.computerEquipments.length > 0 ? 'float-right' : 'float-left'">
									<div class="cursor-pointer" @click="handleAddNewEqp">
										<PlusCircle :size="16" color="#00A09C" class="mr-2" /> <b>{{ FormMSG(24, 'Add equipment') }}</b>
									</div>
								</div>
							</div>
						</fieldset>
					</div>
				</div>
			</div>
			<!-- <b-col>
					<b-tooltip target="delete" placement="top">{{ FormMSG(5, 'Delete') }}</b-tooltip>
					<div @click="handleDelete(premise.id)" class="cursor-pointer" id="delete">
						<MinusCircle color="tomato" :size="30" />
					</div>P
				</b-col> -->
		</div>
		<!-- <b-modal
			header-class="header-class-modal-doc-package"
			ref="modal"
			v-model="openOffsetLocationModal"
			:title="FormMSG(547, 'Add address')"
			size="xl"
			centered
			no-close-on-backdrop
			ok-variant="success"
			@ok="handleUpdateAddress"
			:ok-title="FormMSG(300, 'Save')"
			:cancel-title="FormMSG(301, 'Cancel')"
		>
			<AddressForm :edit-data="premise.address5" @change="handleAddressFormChange" />
		</b-modal> -->

		<location-entry-carbon-dialog
			:open="showLocationEntryCarbon"
			:location="premise"
			:carbons="carbons"
			is-premise
			@location-entry-carbon:submit-success="handleLocationEntryCarbonSubmitSuccess"
			@location-entry-carbon:close="handleLocationEntryCarbonClose"
		/>
	</b-modal>
</template>
<script>
import globalMixin from '@/mixins/global.mixin';
import languageMessages from '@/mixins/languageMessages';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { MinusCircle, PlusCircle } from 'lucide-vue';
import Treeselect from '@riophae/vue-treeselect';
import premisesEquipment from './premesisEquipment.vue';
import InfoCard from './infoCard.vue';
import { store } from '@/store';
import AddressForm from '@/components/Forms/Address';
import { addressFormatter } from '../../shared/fomaters';
import { newAddress, updateAddress } from '@/cruds/address.crud';
import { createAndUpdateComputerEquipment, delComputerEquipment } from '../../cruds/premises.crud';
import CoTwoClap from '../../modules/carbonclap/components/green/CoTwoClap.vue';
import { INIT_DATA_CARBON, PREMISE_TYPE_MENU } from '@/shared/constants';
import _ from 'lodash';
import ViewConsumptionEnergy from '../../components/LocationService/ViewConsumptionEnergy.vue';
import LocationEntryCarbonDialog from '../../modules/carbonclap/components/green/LocationEntryCarbonDialog.vue';
import { getLocation } from '@/cruds/locations.crud';
import { noTilde } from '~utils';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { delCarbon } from '@/cruds/carbon.crud';
import MainAddressLocation from '@/components/LocationService/MainAddressLocation';

const BLANK_ADDRESS = {
	city: '',
	country: '',
	box: '',
	street: '',
	number: '',
	state: '',
	other: '',
	zip: ''
};

export default {
	name: 'premiseModal',
	components: {
		LocationEntryCarbonDialog,
		ViewConsumptionEnergy,
		CoTwoClap,
		MinusCircle,
		AddressForm,
		premisesEquipment,
		PlusCircle,
		InfoCard,
		Treeselect,
		'v-address': MainAddressLocation
	},
	mixins: [languageMessages, isSingleProjectMixin, globalMixin, validationMixin],
	props: {
		showPremiseModal: {
			type: Boolean,
			required: true
		},
		isUpdate: {
			type: Boolean,
			required: false,
			default: false
		},
		premiseData: {
			type: Object,
			required: false
		}
	},
	data() {
		return {
			erreur: '',
			openOffsetLocationModal: false,
			equipmentToUpdate: [],
			idToDelete: [],
			update: false,
			productionPhaseMessage: '',
			premise: {
				id: 0,
				setName: '',
				addressId5: 0,
				address5: {
					city: '',
					country: '',
					box: '',
					street: '',
					number: '',
					state: '',
					other: '',
					zip: ''
				},
				kind: 0,
				type: null,
				createdAt: new Date(),
				filmingArea: 0,
				totNbOfDaysOccupation: 0,
				totNbOfManDays: 0,
				energySavingPlan: 0,
				nbOfAirConditioners: 0,
				greenEnergySupplier: 0,
				recycledPaper: 0,
				sortedWaste: 0,
				buildingLess30Yo: 0,
				heatingType: 0,
				placeAirConditionned: false,
				electricityFromPhotovoltaicSource: false,
				estimation: false,
				preproduction: false,
				shooting: false,
				postProduction: false,
				placeHeated: false,
				workingFromHomeActivity: 0,
				computerEquipments: []
			},
			carbonData: _.cloneDeep(INIT_DATA_CARBON),

			showLocationEntryCarbon: false,
			carbons: [],
			canceling: false,
			selectedPhase: []
		};
	},
	mounted() {
		this.$v.$touch();
	},
	watch: {
		isUpdate: {
			handler(bool) {
				this.update = bool;
			},
			immediate: true,
			deep: true
		},
		premiseData: {
			handler(val) {
				if (!_.isNil(val)) {
					// console.log('PREMISE DATA', val);
					if (val.type === 0) {
						val.type = null;
					}
					this.premise = val;
					this.carbonData = val.carbon;
				}
			},
			immediate: true,
			deep: true
		}
	},
	computed: {
		yesNoOptions() {
			const options = [
				{ text: this.FormMSG(15, 'No'), value: false },
				{ text: this.FormMSG(14, 'Yes'), value: true }
			];
			return options;
		},
		yesNoIdontKnowOptions() {
			return this.FormMenu(1457);
		},
		heatingType() {
			const menu = [
				{
					value: 0,
					text: this.FormMSG(701, 'Please, select a type'),
					disabled: true
				},
				...this.FormMenu(1458).map((option) => ({ ...option, disabled: false }))
			];

			return menu;
		},
		energySavingPlanMenu() {
			return this.FormMenu(1359);
		},
		validateAtLeastOneProductionPhaseChecked() {
			const { preproduction, shooting, postProduction } = this.premise;
			if (this.premise.type === 1) {
				if (!(preproduction || shooting || postProduction)) {
					return false;
				}
			} else {
				if (!(preproduction || shooting)) {
					return false;
				}
			}
			return true;
		},
		validateSetName() {
			const { setName } = this.premise;
			return setName !== '' ? false : true;
		},
		validateAddress() {
			const { addressId5 } = this.premise;
			return addressId5 !== 0 ? false : true;
		},
		validateAreaOfPremiseIfEstimationTrue() {
			const { estimation, filmingArea } = this.premise;
			if (estimation === true && +filmingArea === 0) {
				return false;
			} else {
				return true;
			}
		},
		validateHeatingType() {
			const { placeHeated, heatingType } = this.premise;

			if (placeHeated === true) {
				if (heatingType === 0) {
					return false;
				} else {
					return true;
				}
			} else {
				return true;
			}
		},
		validateNbOfAirConditioners() {
			const { placeAirConditionned, nbOfAirConditioners, estimation } = this.premise;

			if (placeAirConditionned === true) {
				if (+nbOfAirConditioners === 0 && estimation === false) {
					return false;
				}
				if (+nbOfAirConditioners !== 0 || estimation === true) {
					return true;
				}
			} else {
				return true;
			}
		},
		premiseOptions() {
			let headeroptions = [];

			let offices = this.premiseProductionOffices;
			let designs = this.premiseSetDesignWorkPlaces;
			let hmcs = this.premiseHmcWorkPlaces;

			if (offices.length > 1 && designs.length > 1 && hmcs.length > 1) {
				let office = {
					disabled: true,
					...offices[0]
				};

				let design = {
					disabled: true,
					...designs[0]
				};

				let hmc = {
					disabled: true,
					...hmcs[0]
				};

				headeroptions = [office, design, hmc];

				/*headeroptions.sort((a, b) => {
					if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
						return -1;
					}
					if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
						return 1;
					}

					return 0;
				});*/

				let principalOptions = [];
				let title = {},
					labels = [];

				for (let i = 0; i < headeroptions.length; i++) {
					title = headeroptions[i];
					principalOptions.push(title);

					if (headeroptions[i].value === 0) {
						labels = offices.slice(1);
					} else if (headeroptions[i].value === 2) {
						labels = designs.slice(1);
					} else if (headeroptions[i].value === 5) {
						labels = hmcs.slice(1);
					}

					labels.sort((a, b) => {
						if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
							return -1;
						}
						if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
							return 1;
						}

						return 0;
					});

					principalOptions.push(...labels);
				}

				return principalOptions;
			}
		},
		premiseProductionOffices() {
			const menus = this.FormMenu(PREMISE_TYPE_MENU.OFFICE);

			return menus;
		},
		premiseSetDesignWorkPlaces() {
			const menus = this.FormMenu(PREMISE_TYPE_MENU.DESIGN);

			return menus;
		},
		premiseHmcWorkPlaces() {
			const menus = this.FormMenu(PREMISE_TYPE_MENU.HMC);

			return menus;
		},
		rendTitle() {
			if (this.update) {
				return this.FormMSG(2000, 'Update Premises');
			} else {
				return this.FormMSG(1, 'New Premises');
			}
		},
		isOpen: {
			get: function () {
				return this.showPremiseModal;
			},
			set: function (val) {
				return val;
			}
		}
	},
	methods: {
		handleClickeAddEditConsumption() {
			this.carbons = this.premise.carbon;
			this.showLocationEntryCarbon = true;
		},
		handleChangePlaceAirConditionned() {
			if (this.premise.placeAirConditionned === false) {
				this.premise.estimation = false;
				this.premise.nbOfAirConditioners = 0;
			}
		},
		handleChangeEstimation() {
			if (this.premise.estimation === false) {
				this.premise.nbOfAirConditioners = 0;
			} else {
				let surfaceArea = parseFloat(this.premise.filmingArea);
				if (isNaN(surfaceArea)) {
					surfaceArea = 0;
				}
				estimateAreaNumberOfAirConditioner(surfaceArea).then((data) => {
					this.premise.nbOfAirConditioners = data;
				});
			}
		},
		async getLocation() {
			this.premise = await getLocation(+this.premiseData.id);
		},
		changeSelection() {},
		async handleLocationEntryCarbonSubmitSuccess({ carbons }) {
			const oldPremise = _.cloneDeep(this.premise);

			await this.getLocation();

			this.premise.setName = await oldPremise.setName;
			this.premise.address5 = await oldPremise.address5;
			this.premise.addressId5 = await oldPremise.addressId5;
			this.premise.type = await oldPremise.type;
			this.premise.filmingArea = await oldPremise.filmingArea;
			this.premise.totNbOfDaysOccupation = await oldPremise.totNbOfDaysOccupation;
			this.premise.totNbOfManDays = await oldPremise.totNbOfManDays;
			this.premise.nbOfAirConditioners = await oldPremise.nbOfAirConditioners;
			this.premise.computerEquipments = await oldPremise.computerEquipments;
			this.premise.energySavingPlan = await oldPremise.energySavingPlan;
			this.premise.greenEnergySupplier = await oldPremise.greenEnergySupplier;
			this.premise.recycledPaper = await oldPremise.recycledPaper;
			this.premise.sortedWaste = await oldPremise.sortedWaste;
			this.premise.buildingLess30Yo = await oldPremise.buildingLess30Yo;
			this.premise.heatingType = await oldPremise.heatingType;
			this.premise.placeAirConditionned = await oldPremise.placeAirConditionned;
			this.premise.electricityFromPhotovoltaicSource = await oldPremise.electricityFromPhotovoltaicSource;
			this.premise.estimation = await oldPremise.estimation;
			this.premise.preproduction = await oldPremise.preproduction;
			this.premise.shooting = await oldPremise.shooting;
			this.premise.postProduction = await oldPremise.postProduction;
			this.premise.placeHeated = await oldPremise.placeHeated;
			this.premise.workingFromHomeActivity = await oldPremise.workingFromHomeActivity;
		},
		handleLocationEntryCarbonClose() {
			this.carbons = [];
			this.showLocationEntryCarbon = false;
		},
		handleCoTwoClapChange({ carbonData }) {
			this.carbonData = carbonData;
		},
		async handleDelete(id) {
			this.idToDelete.push(parseInt(id, 10));
			this.premise.computerEquipments = this.premise.computerEquipments.filter((equipment) => {
				return equipment.id != id;
			});
		},
		handleChangeForEqp(val) {
			const index = this.equipmentToUpdate.findIndex((equipment) => equipment.id === val.id);
			if (index !== -1) {
				this.equipmentToUpdate.splice(index, 1, val);
			} else {
				this.equipmentToUpdate.push(val);
			}

			this.premise.computerEquipments = this.premise.computerEquipments.map((equipment) => {
				if (equipment.id === val.id) {
					equipment = val;
				}
				return equipment;
			});
		},
		async handleAddNewEqp() {
			await createAndUpdateComputerEquipment(0, { locationId: parseInt(this.premise.id, 10) }).then((res) => {
				this.premise.computerEquipments.push(res);
			});
		},
		// async handleClickAddAddress() {
		// 	if (this.premise.addressId5 == 0) {
		// 		await newAddress().then((res) => {
		// 			this.premise.addressId5 = res.id;
		// 			delete res.id;
		// 			this.premise.address5 = res;
		// 			if (store.getCurrentProjectConfig().legalEntities.length > 0) {
		// 				this.premise.address5.countryKey = store.getCurrentProjectConfig().legalEntities[0].address.countryKey;
		// 			} else {
		// 				this.premise.address5.countryKey = 16965;
		// 			}
		// 		});
		// 	}
		// 	this.openOffsetLocationModal = true;
		// },
		hideModal() {
			this.$emit('close', this.premise.id, this.canceling);
			this.canceling = false;
		},
		async cancelPremise() {
			if (!this.isUpdate) {
				// const toDelete = this.premise.carbon.map((carbon) => +carbon.id);
				// await delCarbon(toDelete);
				this.canceling = true;
			}
		},
		// async handleDelete(id) {
		// 	this.$emit('premise:Delete', id);
		// },
		formatAddress(payload) {
			return addressFormatter(payload);
		},
		// handleAddressFormChange(payload) {
		// 	this.premise.address5 = {
		// 		...this.premise.address5,
		// 		...payload
		// 	};
		// },
		async handleUpdateAddress() {
			await updateAddress(this.premise.addressId5, { ...this.premise.address5 });
		},
		async handleOk(e) {
			e.preventDefault();

			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			}
			if (
				!this.validateSetName &&
				!this.validateAddress &&
				this.validateAtLeastOneProductionPhaseChecked &&
				this.validateHeatingType &&
				this.validateNbOfAirConditioners &&
				this.validateAreaOfPremiseIfEstimationTrue
			) {
				let payload = {};
				payload = {
					..._.omit(this.premise, ['carbon']),
					id: parseInt(this.premise.id, 10),
					setName: this.premise.setName,
					addressId5: parseInt(this.premise.addressId5, 10),
					filmingArea: parseInt(this.premise.filmingArea, 10),
					totNbOfDaysOccupation: parseInt(this.premise.totNbOfDaysOccupation, 10),
					totNbOfManDays: parseInt(this.premise.totNbOfManDays, 10),
					nbOfAirConditioners: parseInt(this.premise.nbOfAirConditioners, 10),
					buildingLess30Yo: this.premise.buildingLess30Yo,
					heatingType: this.premise.heatingType,
					placeAirConditionned: this.premise.placeAirConditionned,
					electricityFromPhotovoltaicSource: this.premise.electricityFromPhotovoltaicSource,
					estimation: this.premise.estimation,
					preproduction: this.premise.preproduction,
					shooting: this.premise.shooting,
					postProduction: this.premise.postProduction,
					electricityFromPhotovoltaicSource: this.premise.electricityFromPhotovoltaicSource,
					placeHeated: this.premise.placeHeated,
					workingFromHomeActivity: parseInt(this.premise.workingFromHomeActivity, 10),
					computerEquipments: []
				};
				if (this.idToDelete.length > 0) {
					for (const id of this.idToDelete) {
						await delComputerEquipment(parseInt(id, 10)).catch((err) => {
							console.log(err);
						});
					}
				}

				if (this.equipmentToUpdate.length > 0) {
					for (const equipment of this.equipmentToUpdate) {
						await createAndUpdateComputerEquipment(parseInt(equipment.id, 10), equipment);
					}
				}

				this.$emit('premise:Update', payload);
			}
		},
		handleOptionSelected(payload) {
			// console.log({ payload });
		},
		selectableTypeItem(item) {
			return _.isNil(item.disabled) || (!_.isNil(item.disabled) && item.disabled === false);
		},
		handleDynamicAddressChange(payload) {
			this.premise.address5 = {
				...this.premise.address5,
				...payload,
				id: +payload.id
			};
			this.premise.addressId5 = +payload.id;
		},
		handleDynamicAddressDelete() {
			this.premise.addressId5 = 0;
			this.premise.address5 = { ...BLANK_ADDRESS };
		},
		handleAddressMapLinkChange(payload) {
			console.log({ mapAddress: payload });
		}
	},
	validations() {
		return {
			premise: {
				type: {
					required
				}
			}
		};
	}
	// created() {
	// 	console.log('premise', this.premise);
	// }
};
</script>
