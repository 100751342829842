var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-2" },
    [
      _c(
        "b-row",
        { staticClass: "px-1" },
        [
          _c(
            "b-col",
            { attrs: { cols: 3 } },
            [
              _c("treeselect", {
                attrs: { options: _vm.equipmentOption },
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.equipment.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.equipment, "type", $$v)
                  },
                  expression: "equipment.type",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: 3 } },
            [
              _c("b-form-input", {
                attrs: { type: "text" },
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.equipment.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.equipment, "description", $$v)
                  },
                  expression: "equipment.description",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: 2 } },
            [
              _c("b-form-input", {
                attrs: { type: "number" },
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.equipment.quantity,
                  callback: function ($$v) {
                    _vm.$set(_vm.equipment, "quantity", $$v)
                  },
                  expression: "equipment.quantity",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: 3 } },
            [
              _c("v-select", {
                attrs: {
                  options: _vm.depreciationOption,
                  label: "text",
                  reduce: (option) => option.value,
                  placeholder: _vm.FormMSG(700, "Please select"),
                  clearable: false,
                  selectable: _vm.selectableTypeItem,
                },
                on: { input: _vm.handleChange },
                scopedSlots: _vm._u([
                  {
                    key: "option",
                    fn: function (option) {
                      return [
                        _c(
                          "div",
                          {
                            class: `${
                              (option.disabled && option.disabled === true) ||
                              (option.selectable && option.selectable === true)
                                ? "text-bold"
                                : "text-color ml-3"
                            }`,
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(option.text) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.equipment.depreciation,
                  callback: function ($$v) {
                    _vm.$set(_vm.equipment, "depreciation", $$v)
                  },
                  expression: "equipment.depreciation",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticStyle: { "align-items": "center", display: "flex" },
              attrs: { cols: 1 },
            },
            [
              _c(
                "div",
                {
                  staticClass: "cursor-pointer",
                  on: {
                    click: function ($event) {
                      return _vm.handleDelete(_vm.equipment.id)
                    },
                  },
                },
                [_c("MinusCircle", { attrs: { color: "tomato", size: 20 } })],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }