<template>
	<errorContainer :error="erreur">
		<div class="animated fadeIn">
			<div class="no-border" :class="{ 'p-3': !showForGreen }">
				<b-row class="no-border" style="flex-direction: row">
					<b-col :cols="6">
						<p class="fw-400">
							<span class="info"><Info :size="15" /> &nbsp;</span
							>{{ FormMSG(10, 'Premises are rented office spaces that are not related to filming locations.') }}
						</p>
					</b-col>
				</b-row>
				<b-col :cols="12" class="text-right mb-2">
					<b-button @click="handleCreateNewAndShowModal"><Plus :size="15" /> {{ FormMSG(32, 'New Premises') }} </b-button>
				</b-col>
				<div :class="{ 'px-4': !showForGreen }">
					<b-table
						bordered
						:selected-variant="selectedColor"
						small
						@row-dblclicked="rowClicked"
						selectable
						sticky-header="800px"
						:select-mode="selectMode"
						:empty-text="FormMSG(98, 'No premises found')"
						style="text-align: left"
						responsive="sm"
						head-variant="dark"
						striped
						class="fw-400"
						:items="premises"
						:fields="showForGreen ? fieldsForGreen : premFields"
						show-empty
					>
						<template #head(createdAt)="">
							<div class="text-left pl-2">
								{{ FormMSG(6, 'Date') }}
							</div>
						</template>
						<template #head(checked)="">
							<div v-if="premises.length > 0" class="text-center pl-2">
								<b-form-checkbox size="lg" v-model="selectAll" @change="handleInputSelectAll" />
							</div>
						</template>
						<template #cell(checked)="data">
							<div class="text-center">
								<b-form-checkbox v-model="data.item.checked" size="lg" @change="handleInputItemChecked($event, data.item)" />
							</div>
						</template>
						<template #cell(option)="data">
							<div class="text-center cursor-pointer" @click="rowClicked(data.item)">
								<Edit color="#00a09c" :size="20" />
							</div>
						</template>
						<template #cell(generatorType)="{ index }">
							<div @click.stop="handleClickStop">
								<b-form-select :value="0" :options="fuelTypeOptions" @change="handleChangeGeneratorFuelType($event, index)" />
							</div>
						</template>
					</b-table>
					<b-button :disabled="premiseId <= 0" @click="multipleDelete" variant="custom-outline-gray" class="w-10" v-if="premises.length > 0">
						{{ FormMSG(350, 'Delete') }}
					</b-button>
				</div>
				<premise-modal
					@close="hideModal"
					:isUpdate="isUpdate"
					@premise:Update="handleUpdate"
					:showPremiseModal="showPremiseModal"
					:premiseData="premise"
				/>
			</div>
		</div>
	</errorContainer>
</template>
<script>
import globalMixin from '@/mixins/global.mixin';
import languageMessages from '@/mixins/languageMessages';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { Edit, Info, Plus, PlusCircle } from 'lucide-vue';
import premiseModal from './premiseModal.vue';
import { getProjectLocationListForPremises, createPremise, deleteLocation, updatePremise } from '../../cruds/premises.crud';
import moment from 'moment';
import { addressFormatter } from '../../shared/fomaters';
import { deleteLocations, getLocation } from '../../cruds/locations.crud';
import _ from 'lodash';
import { mapActions } from 'vuex';
import { PREMISE_TYPE_MENU } from '@/shared/constants';

export default {
	name: 'premises',
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	data() {
		return {
			premiseId: [],
			erreur: {},
			premises: [],
			showPremiseModal: false,
			premise: {
				setName: '',
				addressId5: 0,
				address5: {
					city: '',
					country: '',
					box: '',
					street: '',
					number: '',
					state: '',
					other: '',
					zip: ''
				},
				kind: 0,
				type: 0,
				createdAt: new Date(),
				filmingArea: 0,
				totNbOfDaysOccupation: 0,
				totNbOfManDays: 0,
				energySavingPlan: 0,
				nbOfAirConditioners: 0,
				greenEnergySupplier: 0,
				recycledPaper: 0,
				sortedWaste: 0,
				buildingLess30Yo: 0,
				heatingType: 0,
				placeAirConditionned: false,
				electricityFromPhotovoltaicSource: false,
				estimation: false,
				preproduction: false,
				shooting: false,
				postProduction: false,
				placeHeated: false,
				workingFromHomeActivity: 0,
				computerEquipments: []
			},
			selectMode: 'single',
			selectedColor: 'primary',
			selectAll: false,
			isNew: false,
			isUpdate: false
		};
	},
	components: { PlusCircle, premiseModal, Info, Plus, Edit },
	async created() {
		this.setDetailsLocationForGreenManager(false);

		await this.getLocation();
	},
	computed: {
		fuelTypeOptions() {
			return this.FormMenu(1402);
		},
		showForGreen() {
			return this.$route.name === 'greenPremises';
		},
		fieldsForGreen() {
			return [
				{
					key: 'checked',
					label: '',
					class: 'miniWidth',
					sortable: false,
					tdClass: 'pt-2'
				},
				{
					key: 'no',
					label: this.FormMSG(168, 'No.'),
					formatter: (value, key, item) => {
						return item.id;
					},
					class: 'text-center',
					sortable: true,
					tdClass: 'pt-3'
				},
				{
					key: 'setName',
					label: this.FormMSG(126, 'Name'),
					sortable: true,
					tdClass: 'pt-3'
				},
				{
					key: 'city',
					label: this.FormMSG(369, 'Country'),
					sortable: true,
					formatter: (value, key, item) => {
						return item.address5.country;
					},
					tdClass: 'pt-3'
				},
				{
					key: 'type',
					label: this.FormMSG(7, 'Type'),
					sortable: true,
					formatter: (value) => {
						const text = this.premiseOptions.find((menu) => menu.value === value);
						return text.text;
					},
					class: 'pl-3',
					tdClass: 'pt-3'
				},
				{
					key: 'filmingArea',
					label: this.FormMSG(425, 'Area'),
					sortable: true,
					formatter: (value) => {
						return value + ' ' + this.FormMSG(379, 'm2');
					},
					class: 'text-right',
					tdClass: 'pt-3'
				},
				{
					key: 'totNbOfDaysOccupation',
					label: this.FormMSG(426, 'Days used'),
					sortable: true,
					class: 'text-right',
					tdClass: 'pt-3'
				},
				{
					key: 'totNbOfManDays',
					label: this.FormMSG(11, 'Man days'),
					sortable: true,
					class: 'text-right',
					tdClass: 'pt-3'
				}
			];
		},
		premFields() {
			let fields = [
				{
					key: 'checked',
					sortable: true,
					class: 'miniWidth'
				},
				{
					key: 'createdAt',
					label: this.FormMSG(6, 'Date'),
					formatter: (value) => {
						return new moment(value).format('DD/MM/YYYY');
					},
					sortable: true,
					class: 'text-center pl-3'
				},
				{
					key: 'id',
					label: this.FormMSG(5, 'No.'),
					formatter: (value) => {
						return 'P' + value;
					},
					sortable: true,
					class: 'pl-3'
				},
				{
					key: 'setName',
					label: this.FormMSG(185, 'Name'),
					sortable: true,
					class: 'pl-3'
				},
				{
					key: 'type',
					label: this.FormMSG(7, 'Type'),
					sortable: true,
					formatter: (value) => {
						// const text = this.FormMenu(1400).find((menu) => menu.value === value);
						const text = this.premiseOptions.find((menu) => menu.value === value);
						return text.text;
					},
					class: 'pl-3'
				},
				{
					key: 'address5',
					label: this.FormMSG(70, 'Address'),
					sortable: true,
					formatter: (value) => {
						return addressFormatter(value);
					},
					class: 'pl-3'
				},
				{
					key: 'address5.city',
					label: this.FormMSG(8, 'City'),
					sortable: true,
					class: 'pl-3'
				},
				{
					key: 'address5.country',
					label: this.FormMSG(9, 'Country'),
					sortable: true,
					class: 'pl-3'
				},
				{
					key: 'totNbOfDaysOccupation',
					label: this.FormMSG(13, 'Days of occupation'),
					sortable: true,
					class: ' pl-3'
				},
				{
					key: 'totNbOfManDays',
					label: this.FormMSG(11, 'Man days'),
					sortable: true,
					class: ' pl-3'
				}
				// {
				// 	key: 'option',
				// 	label: this.FormMSG(12, 'Option')
				// }
			];
			return fields;
		},
		premiseOptions() {
			let menus = [];

			let offices = this.premiseProductionOffices;
			let designs = this.premiseSetDesignWorkPlaces;
			let hmcs = this.premiseHmcWorkPlaces;

			if (offices.length > 1 && designs.length > 1 && hmcs.length > 1) {
				menus = [...offices, ...designs, ...hmcs];
			}

			return menus;
		},
		premiseProductionOffices() {
			const menus = this.FormMenu(PREMISE_TYPE_MENU.OFFICE);

			return menus;
		},
		premiseSetDesignWorkPlaces() {
			const menus = this.FormMenu(PREMISE_TYPE_MENU.DESIGN);

			return menus;
		},
		premiseHmcWorkPlaces() {
			const menus = this.FormMenu(PREMISE_TYPE_MENU.HMC);

			return menus;
		}
	},
	methods: {
		...mapActions({
			setDetailsLocationForGreenManager: 'locations/setDetailsForGreenManager'
		}),

		handleClickStop() {},
		handleChangeGeneratorFuelType(payload, index) {
			this.premises[index].generatorFuelType = payload;
		},
		async multipleDelete() {
			const action = async () => {
				await deleteLocations(this.premiseId);
				this.premiseId = [];
				this.selectAll = false;
				this.getLocation();
			};

			this.confirmModal(action, this.FormMSG(58, 'Are you sure ?'));
			// await deleteLocations(this.premiseId);
		},
		handleInputItemChecked(event, premise) {
			if (event) {
				this.premiseId.push(parseInt(premise.id, 10));
			} else {
				const index = this.premiseId.indexOf(parseInt(premise.id, 10));
				if (index > -1) {
					this.premiseId.splice(index, 1);
				}
			}
		},
		handleInputSelectAll(event) {
			const isChecked = event;
			this.premiseId = [];
			this.premises = this.premises.map((premise) => {
				if (isChecked) {
					this.premiseId.push(parseInt(premise.id, 10));
				}
				return {
					...premise,
					checked: isChecked
				};
			});
		},
		async rowClicked(item) {
			this.isUpdate = true;
			this.isNew = false;
			this.premise = await getLocation(item.id);
			this.showPremiseModal = true;
		},
		async handleUpdate(payload) {
			let Id = payload.id;
			delete payload.id;
			let updateField = payload;
			// console.log(Id, updateField);
			await updatePremise(parseInt(Id, 10), updateField);
			await this.getLocation();
			this.showPremiseModal = false;
		},
		async hideModal(id, canceling = false) {
			if (this.isNew && canceling) await this.handleDelete(parseInt(id, 10));
			this.getLocation();
			this.showPremiseModal = false;
		},
		async handleDelete(id) {
			try {
				const res = await deleteLocation(id);
				if (res) {
					this.premises = this.premises.filter((premise) => premise.id !== id);
				}
			} catch (err) {
				console.log(err);
			}
		},
		async handleCreateNewAndShowModal() {
			this.isUpdate = false;
			this.isNew = true;
			await this.handleAddPremises();
			this.showPremiseModal = true;
		},
		async handleAddPremises() {
			await createPremise(2)
				.then((newPremise) => {
					this.premise = newPremise;
				})
				.catch((err) => {
					this.erreur = err;
					this.createToastForMobile(this.FormMSG(368, 'Error'), err.message, '', 'danger');
				});
		},
		async getLocation() {
			await getProjectLocationListForPremises()
				.then((premise) => {
					this.premises = premise;
				})
				.catch((err) => {
					this.erreur = err;
				});
		}
	}
};
</script>
<style>
.miniWidth {
	width: 30px;
}
.no-border {
	border: none;
}
.custom-control-input:checked ~ .custom-control-label::before {
	color: #fff;
	border-color: #00a09c;
	background-color: #00a09c;
	border: 2px solid white;
}
.info {
	font-size: 16px;
	color: #225cbd;
}
.fs-15 {
	font-size: 15px;
}
.align-center {
	align-items: center;
}
</style>
