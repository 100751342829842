var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "parent" }, [
    _c(
      "div",
      { staticClass: "infoHover" },
      [
        _c("Info", {
          staticClass: "pb-1",
          attrs: { color: "#225cbd", size: 15 },
        }),
        _c("div", { class: _vm.tooltipClass }, [
          _c(
            "div",
            { staticClass: "my-header" },
            [
              _c("Info", { attrs: { color: "#225cbd" } }),
              _vm._v(" " + _vm._s(_vm.FormMSG(1, "Information"))),
            ],
            1
          ),
          _c("div", { staticClass: "card card-body" }, [_vm._t("default")], 2),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }