var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "premiseModal",
      attrs: {
        id: "premiseModal",
        "header-class": "header-class-modal-doc-package",
        title: _vm.rendTitle,
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
        size: "xl",
        "cancel-variant": "custom-outline-gray",
        "ok-title": _vm.FormMSG(300, "Save"),
      },
      on: {
        hidden: _vm.hideModal,
        close: _vm.cancelPremise,
        ok: _vm.handleOk,
        cancel: _vm.cancelPremise,
      },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c("div", [
        _c("div", [
          _c("div", [
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "text-right" }, [
                _c("label", [
                  _vm._v(_vm._s(_vm.FormMSG(51, "Premise no.")) + " "),
                  _c("span", { staticClass: "idSpan" }, [
                    _vm._v("P" + _vm._s(_vm.premise.id)),
                  ]),
                ]),
              ]),
              _c(
                "fieldset",
                { staticClass: "scheduler-border" },
                [
                  _c("legend", { staticClass: "scheduler-border" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t\t" +
                        _vm._s(_vm.FormMSG(200, "General")) +
                        "\n\t\t\t\t\t\t"
                    ),
                  ]),
                  _c(
                    "b-row",
                    { staticClass: "pt-3" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: 6 } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(3, "Name"),
                                "label-for": "name",
                              },
                            },
                            [
                              _c("b-form-input", {
                                staticClass: "form-control",
                                attrs: { id: "name", type: "text" },
                                model: {
                                  value: _vm.premise.setName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.premise, "setName", $$v)
                                  },
                                  expression: "premise.setName",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.validateSetName
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(
                                        4,
                                        "Please, enter a valid name"
                                      )
                                    ) +
                                    "\n\t\t\t\t\t\t\t\t"
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(6, "Address"),
                                "label-for": "address",
                              },
                            },
                            [
                              _c("v-address", {
                                attrs: {
                                  params: {
                                    title: _vm.FormMSG(547, "Add address"),
                                  },
                                  "address-id": _vm.premise.addressId5,
                                },
                                on: {
                                  change: _vm.handleDynamicAddressChange,
                                  delete: _vm.handleDynamicAddressDelete,
                                  "address-maplink-change":
                                    _vm.handleAddressMapLinkChange,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.validateAddress
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(
                                        61,
                                        "Please, enter an address valid"
                                      )
                                    ) +
                                    "\n\t\t\t\t\t\t\t\t"
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "6", lg: "6", xl: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(10, "Type of premises"),
                                "label-for": "type",
                              },
                            },
                            [
                              _c("v-select", {
                                class: {
                                  "is-invalid": _vm.$v.premise.type.$error,
                                },
                                attrs: {
                                  options: _vm.premiseOptions,
                                  label: "text",
                                  reduce: (option) => option.value,
                                  placeholder: _vm.FormMSG(
                                    700,
                                    "Select a type"
                                  ),
                                  clearable: false,
                                  selectable: _vm.selectableTypeItem,
                                },
                                on: {
                                  "option:selected": _vm.handleOptionSelected,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "option",
                                    fn: function (option) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            class: `${
                                              (option.disabled &&
                                                option.disabled === true) ||
                                              (option.selectable &&
                                                option.selectable === true)
                                                ? "text-bold"
                                                : "text-color ml-3"
                                            }`,
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(option.text) +
                                                "\n\t\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.premise.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.premise, "type", $$v)
                                  },
                                  expression: "premise.type",
                                },
                              }),
                              _vm.$v.premise.type.$error
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              701,
                                              "Please, select a type"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: this.FormMSG(220, "Production phase"),
                                "label-for": "checkbox-group-1",
                              },
                            },
                            [
                              _c(
                                "b-row",
                                { staticClass: "lh-20" },
                                [
                                  _c(
                                    "b-col",
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass: "pj-cb pb-1",
                                          attrs: {
                                            id: "preproduction",
                                            size: "lg",
                                          },
                                          model: {
                                            value: _vm.premise.preproduction,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.premise,
                                                "preproduction",
                                                $$v
                                              )
                                            },
                                            expression: "premise.preproduction",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  217,
                                                  "Preproduction"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass: "pj-cb pb-1",
                                          attrs: { id: "shooting", size: "lg" },
                                          model: {
                                            value: _vm.premise.shooting,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.premise,
                                                "shooting",
                                                $$v
                                              )
                                            },
                                            expression: "premise.shooting",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(218, "Shooting")
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    [
                                      _vm.premise.type === 1
                                        ? _c(
                                            "b-form-checkbox",
                                            {
                                              staticClass: "pj-cb pb-1",
                                              attrs: {
                                                id: "postproduction",
                                                size: "lg",
                                              },
                                              model: {
                                                value:
                                                  _vm.premise.postProduction,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.premise,
                                                    "postProduction",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "premise.postProduction",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      219,
                                                      "Postproduction"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              !_vm.validateAtLeastOneProductionPhaseChecked
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              702,
                                              "Please, check at least one production phase"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "fieldset",
                { staticClass: "scheduler-border" },
                [
                  _c("legend", { staticClass: "scheduler-border" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t\t" +
                        _vm._s(_vm.FormMSG(204, "Activities and occupation")) +
                        "\n\t\t\t\t\t\t"
                    ),
                  ]),
                  _c(
                    "b-row",
                    { staticClass: "pt-3 pb-4" },
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", { attrs: { for: "occupation" } }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(
                                            11,
                                            "Total no. of days occupation"
                                          )
                                        ) +
                                        "  \n\t\t\t\t\t\t\t\t\t\t\t"
                                    ),
                                    _c("info-card", [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              40,
                                              "Total number of days that the property will be occupied. Make sure to not include holidays or weekends (if applicable)."
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: { id: "occupation", type: "number" },
                                    model: {
                                      value: _vm.premise.totNbOfDaysOccupation,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.premise,
                                          "totNbOfDaysOccupation",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "premise.totNbOfDaysOccupation",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass:
                                            "bg-color-north-wind text-color-white",
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "font-weight-bold" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.FormMSG(49, "Days")
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", { attrs: { for: "occupation" } }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.FormMSG(205, "Office activity")
                                      ) + "  "
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: { id: "occupation", type: "number" },
                                    model: {
                                      value: _vm.premise.totNbOfManDays,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.premise,
                                          "totNbOfManDays",
                                          $$v
                                        )
                                      },
                                      expression: "premise.totNbOfManDays",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass:
                                            "bg-color-north-wind text-color-white",
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "font-weight-bold" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.FormMSG(207, "man-days")
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", { attrs: { for: "occupation" } }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.FormMSG(
                                          208,
                                          "Working from home activity"
                                        )
                                      ) + "  "
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: { id: "occupation", type: "number" },
                                    model: {
                                      value:
                                        _vm.premise.workingFromHomeActivity,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.premise,
                                          "workingFromHomeActivity",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "premise.workingFromHomeActivity",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass:
                                            "bg-color-north-wind text-color-white",
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "font-weight-bold" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.FormMSG(207, "man-days")
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "fieldset",
                { staticClass: "scheduler-border" },
                [
                  _c("legend", { staticClass: "scheduler-border" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t\t" +
                        _vm._s(_vm.FormMSG(209, "Additional info")) +
                        "\n\t\t\t\t\t\t"
                    ),
                  ]),
                  _c(
                    "b-row",
                    { staticClass: "pt-3" },
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(30, "Area premises"),
                                "label-for": "area",
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: { type: "number", id: "area" },
                                    model: {
                                      value: _vm.premise.filmingArea,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.premise,
                                          "filmingArea",
                                          $$v
                                        )
                                      },
                                      expression: "premise.filmingArea",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass:
                                            "bg-color-north-wind text-color-white",
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "font-weight-bold" },
                                            [
                                              _vm._v("m"),
                                              _c("sup", [_vm._v("2")]),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              !_vm.validateAreaOfPremiseIfEstimationTrue
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              704,
                                              "Area of premise cannot be 0"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                        [
                          _c("label", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(
                                        210,
                                        "Is the building less than 30 years old ?"
                                      )
                                    ) +
                                    "  \n\t\t\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                          ]),
                          _c("b-form-radio-group", {
                            staticClass: "radioStyleCheckBox pl-2 pj-cb pt-1",
                            attrs: {
                              options: _vm.yesNoIdontKnowOptions,
                              name: "radio-inliness",
                              size: "lg",
                            },
                            model: {
                              value: _vm.premise.buildingLess30Yo,
                              callback: function ($$v) {
                                _vm.$set(_vm.premise, "buildingLess30Yo", $$v)
                              },
                              expression: "premise.buildingLess30Yo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "pj-cb pb-1",
                              attrs: { id: "placeheated" },
                              model: {
                                value: _vm.premise.placeHeated,
                                callback: function ($$v) {
                                  _vm.$set(_vm.premise, "placeHeated", $$v)
                                },
                                expression: "premise.placeHeated",
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(215, "Place heated")) +
                                  "\n\t\t\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "pj-cb pb-1",
                              attrs: { id: "placeAirConditioned" },
                              on: {
                                change: _vm.handleChangePlaceAirConditionned,
                              },
                              model: {
                                value: _vm.premise.placeAirConditionned,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.premise,
                                    "placeAirConditionned",
                                    $$v
                                  )
                                },
                                expression: "premise.placeAirConditionned",
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.FormMSG(216, "Place air-conditionned")
                                  ) +
                                  "\n\t\t\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "pt-3" },
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                        [
                          _vm.premise.placeHeated
                            ? _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(212, "Heating type"),
                                    "label-for": "type",
                                  },
                                },
                                [
                                  _c("v-select", {
                                    class: {
                                      "is-invalid": !_vm.validateHeatingType,
                                    },
                                    attrs: {
                                      options: _vm.heatingType,
                                      label: "text",
                                      reduce: (option) => option.value,
                                      placeholder: _vm.FormMSG(
                                        700,
                                        "Select a type"
                                      ),
                                      clearable: false,
                                      selectable: _vm.selectableTypeItem,
                                    },
                                    on: {
                                      "option:selected":
                                        _vm.handleOptionSelected,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "option",
                                          fn: function (option) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  class: `${
                                                    (option.disabled &&
                                                      option.disabled ===
                                                        true) ||
                                                    (option.selectable &&
                                                      option.selectable ===
                                                        true)
                                                      ? "text-bold"
                                                      : "text-color ml-3"
                                                  }`,
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(option.text) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2578573759
                                    ),
                                    model: {
                                      value: _vm.premise.heatingType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.premise,
                                          "heatingType",
                                          $$v
                                        )
                                      },
                                      expression: "premise.heatingType",
                                    },
                                  }),
                                  !_vm.validateHeatingType
                                    ? _c(
                                        "div",
                                        { staticClass: "invalid-feedback" },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  701,
                                                  "Please, select a type"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                        [
                          _vm.premise.placeAirConditionned
                            ? _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(
                                      17,
                                      "No. of air conditionners"
                                    ),
                                    "label-for": "airCond",
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        class: _vm.premise.estimation
                                          ? "bg-color-grams-hair"
                                          : "",
                                        attrs: {
                                          disabled: _vm.premise.estimation,
                                          id: "airCond",
                                          type: "text",
                                        },
                                        model: {
                                          value:
                                            _vm.premise.nbOfAirConditioners,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.premise,
                                              "nbOfAirConditioners",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "premise.nbOfAirConditioners",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-input-group-text",
                                            {
                                              staticClass:
                                                "bg-color-north-wind text-color-white",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-bold",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          221,
                                                          "Units"
                                                        )
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  !_vm.validateNbOfAirConditioners
                                    ? _c(
                                        "div",
                                        { staticClass: "invalid-feedback" },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  703,
                                                  "The number can't be 0"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.premise.placeAirConditionned
                        ? _c(
                            "b-col",
                            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                            [
                              _c(
                                "b-form-group",
                                { staticClass: "pt-4" },
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      attrs: { size: "lg", switch: "" },
                                      on: {
                                        change: _vm.handleChangeEstimation,
                                      },
                                      model: {
                                        value: _vm.premise.estimation,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.premise,
                                            "estimation",
                                            $$v
                                          )
                                        },
                                        expression: "premise.estimation",
                                      },
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(_vm.FormMSG(213, "Estimation"))
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "fieldset",
                {
                  staticClass: "my-0 py-0 pb-0 mt-4",
                  class: `${
                    _vm.$screen.width <= 576
                      ? "card-inside"
                      : "scheduler-border"
                  }`,
                },
                [
                  _c(
                    "legend",
                    {
                      staticClass: "text-color-rhapsody-in-blue-2",
                      class: `${
                        _vm.$screen.width <= 576
                          ? "card-inside"
                          : "scheduler-border"
                      }`,
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(698, "Consumption")) +
                          "\n\t\t\t\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "pb-4" },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                { staticClass: "pt-4" },
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      model: {
                                        value:
                                          _vm.premise
                                            .electricityFromPhotovoltaicSource,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.premise,
                                            "electricityFromPhotovoltaicSource",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "premise.electricityFromPhotovoltaicSource",
                                      },
                                    },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "label-mobile" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(
                                                214,
                                                "Electricity from photovoltaic source"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("view-consumption-energy", {
                        attrs: { location: _vm.premise, "is-location": false },
                        on: {
                          "view-consumption-energy:add-edit":
                            _vm.handleClickeAddEditConsumption,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "fieldset",
                { staticClass: "scheduler-border" },
                [
                  _c("legend", { staticClass: "scheduler-border" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t\t" +
                        _vm._s(_vm.FormMSG(20, "Computer Equipment")) +
                        "\n\t\t\t\t\t\t"
                    ),
                  ]),
                  _vm.premise.computerEquipments.length > 0
                    ? _c(
                        "b-row",
                        { staticClass: "pl-3" },
                        [
                          _c("b-col", { attrs: { cols: 3 } }, [
                            _c("b", [_vm._v(_vm._s(_vm.FormMSG(21, "Type")))]),
                          ]),
                          _c("b-col", { attrs: { cols: 3 } }, [
                            _c("b", [
                              _vm._v(_vm._s(_vm.FormMSG(22, "Description"))),
                            ]),
                          ]),
                          _c("b-col", { attrs: { cols: 2 } }, [
                            _c("b", [
                              _vm._v(_vm._s(_vm.FormMSG(23, "Quantity"))),
                            ]),
                          ]),
                          _c("b-col", { attrs: { cols: 3 } }, [
                            _c("b", [
                              _vm._v(_vm._s(_vm.FormMSG(25, "Depreciation"))),
                            ]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.premise.computerEquipments.length > 0
                    ? _c(
                        "div",
                        {
                          staticClass: "pl-3",
                          staticStyle: { "margin-top": "-6px" },
                        },
                        _vm._l(
                          _vm.premise.computerEquipments,
                          function (equipment, index) {
                            return _c(
                              "div",
                              { key: index },
                              [
                                _c("premises-equipment", {
                                  attrs: { equipment: equipment },
                                  on: {
                                    delete: _vm.handleDelete,
                                    change: _vm.handleChangeForEqp,
                                  },
                                }),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      )
                    : _c("div", { staticClass: "mt-2 w-100" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.FormMSG(404, "No computer equipment found")
                            ) +
                            "\n\t\t\t\t\t\t"
                        ),
                      ]),
                  _c("div", { staticClass: "mt-3" }, [
                    _c(
                      "div",
                      {
                        staticClass: "mb-3",
                        class:
                          _vm.premise.computerEquipments.length > 0
                            ? "float-right"
                            : "float-left",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "cursor-pointer",
                            on: { click: _vm.handleAddNewEqp },
                          },
                          [
                            _c("PlusCircle", {
                              staticClass: "mr-2",
                              attrs: { size: 16, color: "#00A09C" },
                            }),
                            _c("b", [
                              _vm._v(_vm._s(_vm.FormMSG(24, "Add equipment"))),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _c("location-entry-carbon-dialog", {
        attrs: {
          open: _vm.showLocationEntryCarbon,
          location: _vm.premise,
          carbons: _vm.carbons,
          "is-premise": "",
        },
        on: {
          "location-entry-carbon:submit-success":
            _vm.handleLocationEntryCarbonSubmitSuccess,
          "location-entry-carbon:close": _vm.handleLocationEntryCarbonClose,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }