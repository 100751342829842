<template>
	<div class="mt-2">
		<b-row class="px-1">
			<b-col :cols="3">
				<treeselect @change="handleChange" v-model="equipment.type" :options="equipmentOption"></treeselect>
			</b-col>
			<b-col :cols="3"> <b-form-input @change="handleChange" v-model="equipment.description" type="text"></b-form-input></b-col>
			<b-col :cols="2"> <b-form-input @change="handleChange" v-model="equipment.quantity" type="number"></b-form-input></b-col>
			<b-col :cols="3">
				<v-select
					v-model="equipment.depreciation"
					:options="depreciationOption"
					label="text"
					:reduce="(option) => option.value"
					:placeholder="FormMSG(700, 'Please select')"
					:clearable="false"
					@input="handleChange"
					:selectable="selectableTypeItem"
				>
					<template #option="option">
						<div
							:class="`${
								(option.disabled && option.disabled === true) || (option.selectable && option.selectable === true)
									? 'text-bold'
									: 'text-color ml-3'
							}`"
						>
							{{ option.text }}
						</div>
					</template>
				</v-select>
			</b-col>
			<b-col :cols="1" style="align-items: center; display: flex"
				><div class="cursor-pointer" @click="handleDelete(equipment.id)">
					<MinusCircle color="tomato" :size="20" /></div
			></b-col>
		</b-row>
	</div>
</template>
<script>
import { MinusCircle } from 'lucide-vue';
import Treeselect from '@riophae/vue-treeselect';

import globalMixin from '@/mixins/global.mixin';

import languageMessages from '@/mixins/languageMessages';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
	name: 'premisesEquipment',
	components: { MinusCircle, Treeselect },
	mixins: [languageMessages, globalMixin],
	props: {
		equipment: {
			type: Object,
			required: true
		}
	},
	computed: {
		equipmentOption() {
			const menu = this.FormMenu(1401).map((menu) => {
				return {
					id: menu.value,
					label: menu.text
				};
			});
			return menu;
		},
		depreciationOption() {
			const depreciationMenu = [
				{
					value: 0,
					text: this.FormMSG(701, 'Please, select a type'),
					disabled: true
				},
				...this.FormMenu(1459).map((option) => ({ ...option, disabled: false }))
			];

			return depreciationMenu;
		}
	},
	methods: {
		handleDelete(id) {
			this.$emit('delete', id);
		},
		handleChange() {
			let payload = {
				...this.equipment,
				description: this.equipment.description,
				type: parseInt(this.equipment.type, 10),
				quantity: parseInt(this.equipment.quantity, 10),
				depreciation: parseInt(this.equipment.depreciation, 10)
			};
			console.log('payload', payload);
			this.$emit('change', payload);
		},
		selectableTypeItem(item) {
			return _.isNil(item.disabled) || (!_.isNil(item.disabled) && item.disabled === false);
		}
	}
};
</script>
<style lang=""></style>
