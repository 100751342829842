<template>
	<div class="parent">
		<div class="infoHover">
			<Info color="#225cbd" class="pb-1" :size="15" />
			<div :class="tooltipClass">
				<div class="my-header"><Info color="#225cbd" /> {{ FormMSG(1, 'Information') }}</div>
				<div class="card card-body"><slot></slot></div>
			</div>
		</div>
	</div>
</template>
<script>
import { Info } from 'lucide-vue';
import globalMixin from '@/mixins/global.mixin';
import languageMessages from '@/mixins/languageMessages';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
export default {
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	name: 'infoCard',
	components: { Info },
	props: {
		position: {
			type: String,
			required: false,
			default: 'top-right'
		}
	},
	computed: {
		tooltipClass() {
			return `personalizedTooltip ${this.position}`;
		}
	}
};
</script>
<style lang="scss">
.my-header {
	color: rgb(34, 92, 189);
	background: white;
	padding: 10px;
	font-weight: lighter;
}
.infoHover:hover > .personalizedTooltip {
	display: block !important;
}
.parent {
	position: relative;
}
.personalizedTooltip {
	display: none;
	position: absolute;
	width: 300px;
	border: 1px solid #00000063;
	box-shadow: 0px 0px 24px -7px #00000099;
	.card {
		font-weight: lighter;
		margin: 0;
		padding: 10px 20px;
	}
}
.top-right {
	bottom: 19px;
	left: 6px;
	z-index: 9999;
}
.top-left {
	bottom: 19px;
	right: 6px;
	z-index: 9999;
}
</style>
